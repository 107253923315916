export const insurance_types = [
    // { value: 4, label: 'Home' },
    // { value: 7, label: 'Jet Ski' },
    { value: 8, label: 'Pet' },
    { value: 3, label: 'Travel' },
    // { value: 9, label: 'Wedding' },
    // { value: 10, label: 'Yacht' },
]

export const languages = [
    { value: 1, label: 'English' },
    { value: 2, label: 'Arabic' },
    { value: 3, label: 'Malayalam' },
]

export const assign_leads = [
    { value: 1, label: 'Car' },
    { value: 2, label: 'Health' },
    { value: 3, label: 'Others' },
]

export const is_all_deals = [
    { value: 0, label: 'Self Deals' },
    { value: 1, label: 'All Deals' },
]

export const agent_types = [
    { value: 1, label: 'New Leads' },
    { value: 2, label: 'Renewal' },
    { value: 3, label: 'Both' },
]

export const yesNos = [
    { value: 1, label: 'Yes' },
    { value: 0, label: 'No' },
]

export const statuses = [
    { value: 1, label: 'Active' },
    { value: 0, label: 'Inactive' },
]