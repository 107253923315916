<template>
    <div
        class="card"
    >
        <div class="card-body">
            <div class="container">
                <el-form
                    ref="ruleFormRef"
                    :model="addUser"
                    :rules="rules"
                    label-position="top"
                    v-if="addUser"
                >
                    <div class="row">
                        <div class="col-sm-6">
                            <el-form-item
                                prop="name"
                            >
                                <label for="name" class="required">Name</label>
                                <el-input
                                    v-model="addUser.name"
                                    id="name"
                                    placeholder="Name"
                                    ref="name"
                                />
                            </el-form-item>
                        </div>
                        <div class="col-sm-6">
                            <el-form-item
                                prop="email"
                            >
                                <label for="email" class="required">Username</label>
                                <el-input
                                    v-model="addUser.email"
                                    id="email"
                                    placeholder="email"
                                    ref="email"
                                    @input="clearValidation"
                                />
                            </el-form-item>
                        </div>
                        <div class="col-sm-6">
                            <el-form-item
                                prop="official_email"
                            >
                                <label for="official_email" class="required">Official Email</label>
                                <el-input
                                    v-model="addUser.official_email"
                                    id="official_email"
                                    placeholder="Official Email"
                                    ref="official_email"
                                />
                            </el-form-item>
                        </div>
                        <div class="col-sm-6">
                            <el-form-item
                                prop="office_mobile_number"
                            >
                                <label for="office_mobile_number">Office Mobile Number</label>
                                <el-input
                                    v-model="addUser.office_mobile_number"
                                    id="office_mobile_number"
                                    placeholder="05xxxxxxxx"
                                    maxlength="10"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    ref="office_mobile_number"
                                />
                            </el-form-item>
                        </div>
                        <div class="col-sm-6">
                            <el-form-item
                                prop="password"
                            >
                                <label for="password" class="required">Password</label>
                                <el-input
                                    :type="!show ? 'password' : 'text'"
                                    v-model="addUser.password"
                                    id="password"
                                    placeholder=""
                                    ref="password"
                                >
                                <template #append>
                                    <i
                                        @click.prevent="show = !show"
                                        :class="show ? 'bi bi-eye-slash' : 'bi bi-eye'"
                                        class="input-group-addon form-control-lg"
                                        /> 
                                    </template>
                                </el-input>

                            </el-form-item>
                        </div>
                        <div class="col-sm-6">
                            <el-form-item
                                prop="password_confirmation"
                            >
                                <label for="password_confirmation" class="required">Confirm Password</label>
                                <el-input
                                    :type="!showConfirm ? 'password' : 'text'"
                                    v-model="addUser.password_confirmation"
                                    id="password_confirmation"
                                    placeholder=""
                                    ref="password_confirmation"
                                >
                                <template #append>
                                    <i
                                        @click.prevent="showConfirm = !showConfirm"
                                        :class="showConfirm ? 'bi bi-eye-slash' : 'bi bi-eye'"
                                        class="input-group-addon form-control-lg"
                                        /> 
                                    </template>
                                </el-input>

                            </el-form-item>
                        </div>
                        <div class="col-sm-6">
                            <el-form-item
                                prop="role_id"
                            >
                                <label for="role_id" class="required">Role</label>
                                <el-select
                                    v-model="addUser.role_id"
                                    style="width: 100%;"
                                    ref="role_id"
                                    filterable
                                >
                                    <el-option :value="0" label="Select Role" />
                                    <el-option v-for="role in roles" :key="role.value" :value="role.value" :label="role.label" />
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="col-sm-6" v-if="[2,9,13].includes(addUser.role_id)">
                            <el-form-item
                                prop="is_underwriter"
                            >
                                <label for="is_underwriter" class="required">Is Underwriter ?</label> <br />
                                <el-radio-group v-model="addUser.is_underwriter">
                                        <el-radio v-for="yesNo in yesNos" :key="yesNo.value" :label="yesNo.value">{{ yesNo.label }}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </div>
                    </div>
                    
                    <template v-if="isShowOptions">
                        <div class="row">
                            <div class="col-sm-6">
                                <el-form-item
                                    prop="is_round_robin"
                                >
                                    <label for="is_round_robin" class="required">In Round Robin</label> <br />
                                    <el-radio-group v-model="addUser.is_round_robin">
                                        <el-radio v-for="yesNo in yesNos" :key="yesNo.value" :label="yesNo.value">{{ yesNo.label }}</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                            <div class="col-sm-6" v-if="addUser.role_id != 4">
                                <el-form-item
                                    prop="skip_round"
                                    v-if="addUser.role_id > 0"
                                >
                                    <label for="skip_round" class="required">Skip Round</label>
                                    <el-input
                                        v-model="addUser.skip_round"
                                        id="skip_round"
                                        placeholder="Skip roundrobin"
                                        ref="skip_round"
                                    />
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row" v-if="(isShowOptions) && addUser.role_id != 4">
                            <div class="col-sm-6">
                                <el-form-item
                                    prop="agent_type"
                                >
                                    <label for="agent_type" class="required">Agent Type </label> <br />
                                    <el-radio-group v-model="addUser.agent_type" @change="agentType">
                                        <el-radio v-for="agent_type in agent_types" :key="agent_type.value" :label="agent_type.value">{{ agent_type.label }}</el-radio>
                                    </el-radio-group>
                                    
                                </el-form-item>
                            </div>
                            <div class="col-sm-6">
                                <el-form-item
                                    prop="is_all_deals"
                                >
                                    <label for="is_all_deals">Renewal Deals </label> <br />
                                    <el-checkbox 
                                        class="me-5" 
                                        v-model="addUser.is_llr" 
                                        :disabled="![2, 3].includes(addUser.agent_type)"
                                        :true-label="1"
                                        :false-label="0"
                                        @change="isLlr"
                                    > 
                                        Lost Lead 
                                    </el-checkbox>

                                    <el-radio-group v-model="addUser.is_all_deals" :disabled="![2, 3].includes(addUser.agent_type) || !addUser.is_llr">
                                        <el-radio v-for="is_all_deal in is_all_deals" :key="is_all_deal.value" :label="is_all_deal.value">{{ is_all_deal.label }}</el-radio>
                                    </el-radio-group>
                                    
                                </el-form-item>
                            </div>
                        </div>
                    </template>
                    <div class="row">
                        <div class="col-sm-6"  v-if="![4].includes(addUser.role_id) ">
                            <el-form-item
                                prop="assign_lead"
                                v-if="(isShowOptions)"
                            >
                                <label for="assign_lead" :class="{'required': [2, 13, 9].includes(addUser.role_id)}">Assign Leads Of </label> <br />
                                <el-radio-group v-model="addUser.assign_lead" @change="handleChangeAssignLeads">
                                    <el-radio v-for="assign_lead in assign_leads" :key="assign_lead.value" :label="assign_lead.value">{{ assign_lead.label }}</el-radio>
                                </el-radio-group>
                                
                            </el-form-item>
                        </div>
                        <div class="col-sm-6" v-if="[4].includes(addUser.role_id)">
                            <el-form-item
                                prop="assign_uw_lead"
                                v-if="(isShowOptions)"
                            >
                                <label for="assign_uw_lead" :class="{'required': [4].includes(addUser.role_id)}">Assign Leads Of </label> <br />
                               
                                <el-checkbox-group v-model="addUser.assign_uw_lead" @update:model-value="assignUwLeads">
                                    <el-checkbox v-for="assign_lead in assign_leads" :key="assign_lead.value" :label="assign_lead.value" >{{ assign_lead.label }}</el-checkbox>
                                </el-checkbox-group>
                                
                            </el-form-item>
                        </div>
                        <div class="col-sm-6" v-if="addUser.role_id != 4">
                            <el-form-item
                                prop="assign_language"
                                v-if="isShowOptions"
                            >
                                <label for="assign_language" class="required">Language </label> <br />
                                <el-checkbox-group v-model="addUser.assign_language">
                                    <el-checkbox v-for="language in languages" :key="language.value" :label="language.value"> {{ language.label }} </el-checkbox>
                                </el-checkbox-group>
                                
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <el-form-item
                                prop="misc_insurance_types"
                                v-if="isShowOptions && ((addUser.assign_lead == 3 && addUser.role_id != 4) || (addUser.role_id == 4 && addUser.assign_uw_lead.includes(3)) )"
                            >
                                <label for="misc_insurance_types" class="required">Other Insurance Types</label> <br />
                                <el-checkbox-group v-model="addUser.misc_insurance_types" @change="removeValidation('misc_insurance_types')">
                                    <el-checkbox v-for="insurance_type in insurance_types" :key="insurance_type.value" :label="insurance_type.value">{{  insurance_type.label  }}</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6" v-if="isShowOptions && addUser.role_id == 2">
                            <el-form-item
                                prop="team_leader"
                            >
                                <label for="team_leader" class="required">Assigned Team Leader</label>
                                <el-select
                                    v-model="addUser.team_leader"
                                    style="width: 100%;"
                                    ref="team_leader"
                                    filterable 
                                >
                                    <el-option :value="0" label="Select Team Leader" />
                                    <el-option v-for="tl in tls" :key="tl.value" :value="tl.value" :label="tl.label" />
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="col-sm-6" v-if="isShowOptions && addUser.role_id != 4">
                            <el-form-item
                                prop="policy_agent_id"
                            >
                                <label for="policy_agent_id" class="required">Assigned Underwriter</label>
                                <el-select
                                    v-model="addUser.policy_agent_id"
                                    style="width: 100%;"
                                    ref="policy_agent_id"
                                    @change="removeValidation('policy_agent_id')"
                                    filterable 
                                >
                                    <el-option :value="0" label="Select Underwritter" />
                                    <el-option v-for="uw in cUws" :key="uw.value" :value="uw.value" :label="uw.label" />
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="row" v-if="addUser.is_edit">
                        <div class="col-sm-6">
                            <el-form-item
                                prop="status"
                            >
                                <label for="status" class="required">Status</label> <br />
                                <el-radio-group v-model="addUser.status">
                                    <el-radio v-for="status in statuses" :key="status.value" :label="status.value">{{ status.label }}</el-radio>
                                </el-radio-group>
                                
                            </el-form-item>
                        </div>
                        <div class="col-sm-6 d-flex align-items-end" v-if="addUser.show_archived">
                            <el-form-item
                                prop="is_archived"
                            >
                                <el-checkbox :true-label="1" :false-label="0" v-model="addUser.is_archived">Archived</el-checkbox>
                            </el-form-item>
                        </div>
                        <div class="col-sm-6" v-if="!addUser.status && [2, 9, 13].includes(addUser.role_id) && !addUser.show_archived">
                            <el-form-item
                                prop="agents"
                                v-if="fagents && fagents.length > 0"
                            >
                                <label for="agents" class="required">Select Transfer Agent/s</label> <br />
                                <el-select
                                    v-model="addUser.agents"
                                    style="width: 100%;"
                                    ref="agents"
                                    filterable 
                                    multiple 
                                    @change="clearTransferError"
                                >
                                    <el-option v-for="agent in fagents" :key="agent.value" :value="agent.value" :label="agent.label" />
                                </el-select>
                            </el-form-item>
                            <p class="text-danger" v-if="(!fagents || fagents.length <= 0) && alreadyCalled">
                                The selected Sales Agent does not have any available
                                customers to transfer.
                            </p>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="card-footer">
            <div class="container">
                <div class="row text-center">
                    <div class="col-sm6">
                        <button
                            type="button"
                            class="btn btn-secondary me-2" 
                            @click="cancel"
                         >
                            Cancel
                        </button>
                        <button
                            type="button"
                            class="btn btn-info" 
                            @click="handleClick(ruleFormRef)"
                            :disabled="loading"
                         >
                            <span v-if="loading">
                                Please wait...
                            </span>
                            <span v-else>
                                &nbsp;&nbsp;Save&nbsp;&nbsp;
                            </span>
                        </button>
                    </div>                
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, onMounted, watch, computed, getCurrentInstance } from 'vue'
// import { addUser, setAddUser } from '@/store/composable/User.js'
import Validations from '@/core/services/etc/Validations'
import { useUpdateCustomerData } from '@/store/composable/Customer'
import { languages, insurance_types, assign_leads, is_all_deals, agent_types, yesNos, statuses } from '@/core/utils/User.ts'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { notif } from '@/store/stateless/store';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

const ruleFormRef = ref()
const show = ref(false)
const showConfirm = ref(false)
const roles = ref([])
const tls = ref([])
const uws = ref([])
const fagents = ref([])
const calledTls = ref(false)
const calledUws = ref(false)
const calledAgents = ref(false)
const alreadyCalled = ref(false)
const loading = ref(false)
const isLoaded = ref(false)

const route = useRoute()
const router = useRouter()
const store = useStore()

onMounted(() => {
    getRoles()
    getUser()
})

const addUser = computed(() => store.state.User.addUser)

const isShowOptions = computed(() => {
    return [2, 13, 9, 4].includes(addUser.value.role_id)
})

const cUws = computed(() => {
    if(!addUser.value.is_underwriter) return uws.value
    else return uws.value.filter(x => x.official_email == addUser.value.official_email)
})

watch(() => addUser.value, async(newUser) => {
        if(isShowOptions.value) {
            if(newUser.role_id == 2) await getTls()
            if(newUser.role_id != 4) await getUws()
            if(newUser.status == 0) await getAgents()
        }

        if(newUser.is_underwriter == 1 && newUser.official_email) {
            const selected = uws.value.find(x => x.official_email == newUser.official_email)
            newUser.policy_agent_id = selected ? selected.value : 0
        }

        if(!newUser.is_round_robin) {
            newUser.is_new = 0
        } else {
            newUser.is_new = 1
        }
        
        if(newUser.is_round_robin) {
            if(newUser.skip_round == null || newUser.skip_round == undefined || newUser.skip_round == '')
            newUser.skip_round = 0
        }
        
        if(newUser.role_id != 4 && newUser.assign_lead == 1) {
            newUser.assign_car = 1
            newUser.assign_health = newUser.assign_misc = 0
        }

        if(newUser.role_id != 4 && newUser.assign_lead == 2) {
            newUser.assign_health = 1 
            newUser.assign_car = newUser.assign_misc = 0
        }

        if(newUser.role_id != 4 && newUser.assign_lead == 3) {
            newUser.assign_misc = 1
            newUser.assign_car = newUser.assign_health = 0
        }


        if(newUser.assign_language.length > 0) {
            newUser.assign_ml_leads = newUser.assign_language.filter(x => x == 3).length > 0 ? 1 : 0 
            newUser.assign_ar_leads = newUser.assign_language.filter(x => x == 2).length > 0 ? 1 : 0 
            newUser.assign_en_leads = newUser.assign_language.filter(x => x == 1).length > 0 ? 1 : 0 
        }


}, {deep: true})


function assignUwLeads(){
    if(addUser.value.role_id == 4 && addUser.value.assign_uw_lead.length > 0){
        addUser.value.assign_car = addUser.value.assign_uw_lead.filter(x => x == 1).length > 0 ? 1 : 0 
        addUser.value.assign_health = addUser.value.assign_uw_lead.filter(x => x == 2).length > 0 ? 1 : 0 
        addUser.value.assign_misc = addUser.value.assign_uw_lead.filter(x => x == 3).length > 0 ? 1 : 0 
    }
}

async function getRoles() {
    const response = await useUpdateCustomerData({linkType: 'user-roles'})
    roles.value = response.data.data.roles
}

async function getTls() {
    if(!calledTls.value) {
        calledTls.value = true

        let assign = 'all';
        if(addUser.value.assign_lead == 1)
            assign = 'car'
        else if(addUser.value.assign_lead == 2)
            assign = 'health'
        else if(addUser.value.assign_lead == 3)
            assign = 'misc'

        const response = await useUpdateCustomerData({linkType: 'team-leader-list',assign:assign})
        tls.value = response.data.data.team_leaders
    }
}


async function handleChangeAssignLeads() {

    if(addUser.value.role_id != 4) {
        calledUws.value = false
        calledTls.value = false

        addUser.value.team_leader = null
        addUser.value.policy_agent_id = null
        
        setTimeout(() => {
            ruleFormRef.value.clearValidate('team_leader')
            ruleFormRef.value.clearValidate('policy_agent_id')
        }, 100);

        await getTls()
        await getUws()
    }


}

async function getUws() {
    if(!calledUws.value) {
        calledUws.value = true
        let assign = 'all';
        if(addUser.value.assign_lead == 1)
            assign = 'car'
        else if(addUser.value.assign_lead == 2)
            assign = 'health'
        else if(addUser.value.assign_lead == 3)
            assign = 'misc'

        const response = await useUpdateCustomerData({linkType: 'underwriter-list',assign:assign})
        uws.value = response.data.data.agents
    }
}

async function getAgents() {
    if(!calledAgents.value) {
        calledAgents.value = true
        const response = await useUpdateCustomerData({linkType: 'get-agent-setting-list', agent_id: addUser.value.id })
        fagents.value = response.data.data.agents
        alreadyCalled.value = true
    }
}

async function getUser() {
    if(route.params.userId) {
        setCurrentPageBreadcrumbs('Edit User', ['Users'])
        const response = await useUpdateCustomerData({linkType: 'user-detail', user_id: route.params.userId })
        store.commit('SET_USER', store.getters.getUserData(response.data.data.user))
        assignUwLeads()
        isLoaded.value = true
    } else {
        setCurrentPageBreadcrumbs('Add User', ['Users'])
        store.commit('SET_USER', {
            id: 0,
            user_id: 0,
            agent_count: 0,
            assign_ml_leads:0,
            assign_ar_leads:0,
            assign_car:0,
            assign_en_leads:0,
            assign_health:0,
            assign_misc:0,
            email:"",
            is_all_deals: -1,
            is_both:0,
            is_archived: 0,
            is_llr: 0,
            is_new:0,
            is_renewal:0,
            is_round_robin: -1,
            is_underwriter:0,
            misc_insurance_types: [],
            name:"",
            office_mobile_number:"",
            official_email:"",
            other_mobile_number:"",
            password:"",
            password_confirmation:"",
            policy_agent_id:0,
            role_id:0,
            roles: [],
            skip_round:0,
            status:1,
            team_leader:0,
            agent_type: 0,
            assign_lead: 0,
            assign_uw_lead: [],
            assign_language: [],
            is_edit: false
        })
        isLoaded.value = true
    }

}

const rules = reactive({
    name: [{ validator: Validations.names, trigger: ['blur', 'change'] }],
    email: [{ validator: Validations.username, trigger: ['blur', 'change'] }],
    official_email: [{ validator: Validations.official_email, trigger: ['blur', 'change'] }],
    password: [{ validator: (rule, value, callback) => Validations.password(rule, value, callback, addUser.value.is_edit), trigger: ['blur', 'change'] }],
    password_confirmation: [{ validator: (rule, value, callback) => Validations.password_confirmation(rule, value, callback, addUser.value.password, addUser.value.is_edit), trigger: ['blur', 'change'] }],
    team_leader: [{ validator: Validations.team_leader, trigger: ['blur', 'change'] }],
    policy_agent_id: [{ validator: (rule, value, callback) => Validations.underwriters(rule, value, callback, addUser.value.is_underwriter), trigger: ['blur', 'change'] }],
    is_round_robin: [{ validator: Validations.is_round_robin, trigger: ['blur', 'change'] }],
    role_id: [{ validator: Validations.role_id, trigger: ['blur', 'change'] }],
    assign_lead: [{ validator: Validations.assign_lead, trigger: ['blur', 'change'] }],
    assign_uw_lead: [{ validator: Validations.assign_uw_lead, trigger: ['blur', 'change'] }],
    assign_language: [{ validator: Validations.assign_language, trigger: ['blur', 'change'] }],
    misc_insurance_types: [{ validator: Validations.misc_insurance_types, trigger: ['blur', 'change'] }],
    agents: [{ validator: Validations.agents, trigger: ['blur', 'change'] }],
    status: [{ validator: (rule, value, callback) => Validations.user_status(rule, value, callback, addUser.value.role_id, addUser.value.agent_count), trigger: ['blur', 'change'] }],
    agent_type: [{ validator: Validations.agent_type, trigger: ['blur', 'change'] }],
    is_all_deals: [{ validator: (rule, value, callback) => Validations.is_all_deals(rule, value, callback, addUser.value.agent_type, addUser.value.is_llr), trigger: ['blur', 'change'] }],
})
const inst = getCurrentInstance()

function clearValidation() {
    Validations.user_name_taken = false
}

function clearTransferError(){
    Validations.inactive_agent = false
    Validations.inactive_msg = ''
}


async function handleClick(formEl) {
    clearValidation()

    if(!formEl) return
    formEl.validate(async(valid, fields) => {
        loading.value = true

        if(valid) {
            let isNew = 0
            let isRenewal = 0

            if(addUser.value.agent_type == 1) {
                isNew = 1
                isRenewal = 0
            }

            if(addUser.value.agent_type == 2) {
                isNew = 0
                isRenewal = 1
            }

            if(addUser.value.agent_type == 3) {
                isNew = 1
                isRenewal = 1
            }

            const data = {
                ...addUser.value,
                is_renewal:isRenewal,
                is_new:isNew,
                linkType: addUser.value.is_edit ? 'update-user' : 'add-user'
            }

            const response = await useUpdateCustomerData(data)
            if(response.status < 299) {
                notif.simple('User', 'success', 'User has been added successfully!')
                await cancel()
            }
            else {
                // console.log('error',response.response.data.data)
                if(response.response.data && response.response.data.data && response.response.data.data.email){
                    Validations.user_name_taken = true
                }

                if(response.response.data && response.response.data.data && response.response.data.data.misc_insurance_types)
                    Validations.has_error_with_other_insurance_type = true

                if(response.response.data && response.response.data.data && response.response.data.data.official_email)
                    Validations.has_error_with_assigned_underwriter = true
                
                if(response.response.data && response.response.data.data && response.response.data.data.error){
                // console.log('transfer error',response.response.data.data.error)
                    Validations.inactive_agent = true
                    Validations.inactive_msg = response.response.data.data.error
                }

                formEl.validate((valid, field) => {
                    if(!valid) {
                        const firstError = Object.keys(field)[0]
                        const toError = inst.refs[firstError]
                        if(toError != undefined) {
                            toError.focus()
                        }
                    }
                })
            }
            loading.value = false

        } else {
            const firstError = Object.keys(fields)[0]
            const toError = inst.refs[firstError]
            if(toError != undefined) {
                toError.focus()
            }
            loading.value = false
        }
    })
    
}

function removeValidation(data) {
    if(data == 'misc_insurance_types') {
        Validations.has_error_with_other_insurance_type = false
    }
    
    if(data == 'policy_agent_id') {
        Validations.has_error_with_assigned_underwriter = false
    }

    ruleFormRef.value.clearValidate(data)
}

function agentType() {
    if(addUser.value.agent_type == 1) {
        addUser.value.is_new = 1
        addUser.value.is_both = addUser.value.is_renewal = addUser.value.is_llr = 0
        addUser.value.is_all_deals = 0
    }
    if(addUser.value.agent_type == 2) {
        addUser.value.is_renewal = 1
        addUser.value.is_both = addUser.value.is_new = 0
        addUser.value.is_all_deals = 0
        addUser.value.is_llr = 0
    }
    if(addUser.value.agent_type == 3) {
        addUser.value.is_both = 3
        addUser.value.is_renewal = addUser.value.is_new = 1
        addUser.value.is_all_deals = 0
        addUser.value.is_llr = 0
    }
}

function isLlr() {
    if(addUser.value.is_llr == 1) {
        addUser.value.is_new = 0
        addUser.value.is_renewal = 1
        addUser.value.is_all_deals = -1
    } else {
        addUser.value.is_all_deals = 0
    }
}

async function cancel() {
    await router.push({ name: 'user-list' })
}
</script>